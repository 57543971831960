const Logo = () => (
  <div className="logo">
    <svg
      viewBox="0 0 1380 170"
      width="1380"
      height="170"
      preserveAspectRatio="xMidYMax"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M165.27 96.782H30.728c5.105 25.828 25.943 45.184 53.658 45.184 19.68 0 36.502-9.003 45.819-27.372l31.327.042C148.784 148.854 121.976 167 83.214 167 34.661 167 0 129.79 0 83.618 0 35.958 34.075 0 83.8 0 132.046 0 167 35.763 167 81.851c0 4.37-.32 10.409-1.73 14.931M83.507 25.55c-27.06 0-49.795 20.4-51.691 48.927h103.87c-1.91-28.917-25.092-48.927-52.179-48.927M483.27 96.782H348.728c5.105 25.828 25.943 45.184 53.658 45.184 19.68 0 36.502-9.003 45.819-27.372l31.327.042C466.784 148.854 439.976 167 401.214 167 352.661 167 318 129.79 318 83.618 318 35.958 352.075 0 401.8 0 450.046 0 485 35.763 485 81.851c0 4.37-.32 10.409-1.73 14.931M401.507 25.55c-27.073 0-49.795 20.4-51.691 48.927h103.87c-1.91-28.917-25.092-48.927-52.179-48.927M1378.27 96.782h-134.542c5.105 25.828 25.943 45.184 53.658 45.184 19.68 0 36.502-9.003 45.819-27.372l31.327.042C1361.784 148.854 1334.976 167 1296.2 167c-48.54 0-83.2-37.21-83.2-83.382C1213 35.958 1247.075 0 1296.8 0c48.246 0 83.2 35.763 83.2 81.851 0 4.37-.32 10.409-1.73 14.931m-81.777-71.233c-27.06 0-49.795 20.4-51.678 48.927h103.858c-1.897-28.917-25.079-48.927-52.18-48.927M1163.89 153.882c-15.288 8.22-37.048 10.118-54.276 10.118H1051V8h57.973c22.18 0 49.324 3.168 66.76 18.133C1192.753 40.879 1202 64.283 1202 86.416c0 26.572-13.782 54.4-38.11 67.466m-52.532-123.284H1080.6V141.88h31.916c38.081 0 59.689-22.816 59.689-55.464 0-34.954-26.141-55.668-60.847-55.818M651 164l-43.368-68.547c27.986-4.862 40.507-21.867 40.507-43.53 0-25.099-17.998-43.095-54.595-43.095L507 8v156h29.034V98.903h41.87L617.591 164H651zM536.034 31.266H592.1c17.426 0 25.955 11.164 25.955 22.165 0 11.653-9.074 23.51-27.413 23.51h-54.608V31.266zM211.672 164L161 8h31.418l48.93 152.013h1.303L291.581 8H323l-50.672 156zM1005.862 164H1035L981.412 8h-54.824L873 164h29.138l12.653-37.74h78.418l12.653 37.74zm-83.5-60.31l30.809-91.717h1.658l30.81 91.716H922.36zM766.06 161.46L801.42 8H857v156h-28.193V11.987h-.764L791.795 164h-52.33L702.943 11.987h-.765V164H674V8h55.58l35.633 153.46z"
        fill="#000"
        fillRule="nonzero"
      ></path>
    </svg>
  </div>
);

export default Logo;

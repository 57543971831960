const Marquee = () => (
  <div className="marquee">
    <span>
      Take three rolls and fill appropriately on the other side of the card.
    </span>
    <span>
      Take three rolls and fill appropriately on the other side of the card.
    </span>
    <span>
      Take three rolls and fill appropriately on the other side of the card.
    </span>
    <span>
      Take three rolls and fill appropriately on the other side of the card.
    </span>
  </div>
);

export default Marquee;
